<template>
<div>

</div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    created(){
        this.logout()
    },


    methods: {
        logout() {
            console.log("dddddddddddddddddddddddddddddddddddddddddd")
            clearInterval(this.timeToken);

            var extern = null;
            extern = localStorage.getItem("extern")

            localStorage.setItem("isIdle", false);
            document.onload = null;
            document.onmousemove = null;
            document.onmousedown = null;
            document.ontouchstart = null;
            document.onclick = null;
            document.onscroll = null;
            
            localStorage.removeItem("token");
            localStorage.clear();

            this.$router.push("/micuenta");

            extern = null
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
